import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@layouts';
import { Banners, SEO, Features } from '@components';

const { Banner } = Banners;

const Index = ({ data }) => (
  <Layout
    bannerChildren={
      <Banner
        title='Features'
        description='Get inspired by Africans and people of African descent you may not know about who are making strides in Science, Technology, Engineering, Mathematics, Artificial Intelligence, and more.'
      />
    }
  >
    <SEO
      title='FEATURES'
      description='Get inspired by Africans and people of African descent you may not know about who are making strides in Science, Technology, Engineering, Mathematics, Artificial Intelligence, and more.'
    />
    <Features data={data} />
  </Layout>
);

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { content_type: { in: ["feature", "quote"] } } }
      sort: {
        fields: [frontmatter___publicationDate, frontmatter___date, frontmatter___subject]
        order: [DESC, DESC, ASC]
      }
    ) {
      edges {
        node {
          frontmatter {
            content_type
            slug
            title
            subject
            excerpt
            author
            photo_credit
            category
            date
            quote
            author
            publicationDate
            image_url {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            image_1 {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Index;
